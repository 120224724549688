@import "../../variables.scss";

.NavTopBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7.5vh;

  .logo {
    height: 45%;
    padding-right: 5px;
  }

  .title {
    color: $accent;
    font-weight: 200;
    font-style: italic;
  }
}
