@import "./variables.scss";
@import "./styles/animations.scss";
@import "../node_modules/bulma/bulma.sass";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300+400,600|Raleway:300,300i,400|Sacramento|Source+Sans+Pro:400,600,700|Source+Code+Pro');

html {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-open-sans
}

a {
  color: $peach-dark;
}

.layout {
  max-height: 100vh;
  display: grid;
  grid-template-columns: minmax(250px, 15%) 1fr;
  grid-template-rows: 7.5vh 92.5vh;
  grid-template-areas:  "navTopBar topBar"
                        "navigation main"
                        "navigation main";
  &.transaction-mode {
    grid-template-columns: minmax(250px, 15%) minmax(300px, 20%) 1fr;
    grid-template-rows: 7.5vh 92.5vh;
    grid-template-areas:  "navTopBar topBar topBar"
                          "navigation sidePane main"
                          "navigation sidePane main";
  }
}

.side-pane {
  grid-area: sidePane;
  height: 92.5vh;
  padding: 1rem;
  input, select, .select, button {
    width: 100%;
  }
  overflow: scroll;
}

.nav-top-bar {
  background-color: $peach-dark;
  grid-area: navTopBar;
}

.top-bar {
  background-color: $peach-light;
  grid-area: topBar;
}

.sidebar {
  grid-area: sidebar;
}

.navigation {
  // padding: 30px 10px;
  grid-area: navigation;
  height: 92.5vh;
}

.main {
  grid-area: main;
  height: 92.5vh;
  overflow: auto;
}

.footer {
  grid-area: footer;
  padding-top: 0;
  padding-bottom: 0;
}

.hero {
  &.is-blockstack {
    @extend %is-blockstack;
  }
}

.is-alabaster {
  background-color: $alabaster;
}

.is-bastille {
  background-color: $bastille;
}

.is-prim {
  background-color: $prim;
}

.is-full-height {
  min-height: 100vh;
}

.react-datepicker-popper {
  min-width: 350px;
}
