@import "../../variables.scss";

.Login {
  background-color: $peach-light;

  .title {
    line-height: 1.5;
    color: $accent;
    letter-spacing: 2px;
  }

  .subtitle {
    text-transform: uppercase;
    color: $white;
  }

  button {
    @extend %is-blockstack;
    font-family: $font-family-source-code-pro;
    border: none;
    margin-top: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    &:hover {
      background-color: #361D44;
      color: $white;
    }

    .logo {
      padding-right: 5px;
    }
  }
}
