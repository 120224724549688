@import "../../variables.scss";
@import "../../styles/animations.scss";

.Portfolio {
  .SingleHoldingLayout {
    display: flex;
    flex-wrap: wrap;

    .SingleHoldingContainer {
      display: flex;
      width: 25%;
      min-width: 250px;

      .SingleHolding {
        @extend %fade-in;
        flex: 1;
        padding: 15px;
        box-sizing: border-box;

        .box {
          height: 100%;
        }

        .link-style {
          font-family: $font-family-source-sans-pro;
          color: $peach-dark;
          font-size: 1.1rem;
          font-weight: 600;
          letter-spacing: 2px;
        }
      }
    }

  }
}
